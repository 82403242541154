import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import MensHealthTestimonials from "../components/MensHealthTestimonials";
import Badge from "../components/badge";
import GetStartedButton from "../components/button";
import { Link } from "gatsby";

const AboutUs = () => {
  return (
    <Layout map={false}>
      <SEO
        title="Premier ED Treatment: About Multnomah Medical Clinic"
        description="Premier ED treatment provider, Multnomah Medical Clinic is dedicated to offering the latest breakthroughs in ED treatment available."
      />
      <div className="flex justify-center text-center">
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4">
          <div className="sm:grid sm:grid-cols-5 grid-cols-1 rounded-lg gap-4 mb-10">
            <div className="col-span-3 row-1-thing">
              <h1 className="hidden sm:block text-3xl text-gray-900 font-semibold leading-snug">
                Portland&apos;s Top Choice<br></br>for Men's Health Treatments
              </h1>
              <h1 className="sm:hidden text-3xl text-gray-900 font-semibold leading-snug">
                Portland&apos;s Top Choice for Men's Health Treatments
              </h1>
              <div className="flex gap-3 mt-2 mb-2 items-center flex-wrap">
                <Badge classes="bg-blue-300 text-no-wrap">
                  Erectile dysfunction
                </Badge>
                <Badge classes="bg-blue-300">Peyronie's disease</Badge>
                <Badge classes="bg-blue-300">Pelvic pain</Badge>
                <div
                  className="font-semibold hidden sm:visible"
                  style={{ fontSize: "14px" }}
                >
                  + MORE
                </div>
              </div>
              <div className="text-lg mb-6">
                Pioneering acoustic wave therapy for erectile dysfunction in the
                Pacific Northwest{" "}
                <Link
                  to="/about"
                  className="text-blue-500 hover:text-blue-400 font-medium"
                >
                  for over 4 years
                </Link>
                .
              </div>
              <div className="text-lg mb-6">
                Our approach requires no surgery, no shots, and no pills. We
                deliver highly effective and rapid results that are{" "}
                <Link
                  to="/science"
                  className="text-blue-500 hover:text-blue-400 font-medium"
                >
                  clinically proven and successful worldwide
                </Link>
                .
              </div>
              <GetStartedButton linkTo="/contact-us" className="mb-4 pb-4">
                Book an appointment
              </GetStartedButton>
            </div>
            <div
              className="col-span-2 w-full flex row-1-thing mb-4"
              style={{ minHeight: "300px" }}
            >
              <iframe
                width="560"
                height="315"
                className="mt-4"
                src="https://www.youtube.com/embed/mqbSGXYqnTY?rel=0&controls=0&modestbranding=1&fs=0&showinfo=0"
                title="ED Testimonial"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-6  pt-4 pb-10 bg-blue-50">
          <div className="text-2xl font-semibold mb-2">Popular treatments</div>
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-4 sm:gap-y-1 gap-y-4">
            <div>
              <div className="font-medium text-lg mb-2">
                Erectile dysfunction
              </div>
              <div style={{ textWrap: "balanced" }}>
                Pulsating waves stimulate the penile tissue and encourage blood
                flow and promote the growth of new blood vessels, leading to
                firmer, longer-lasting erections.
              </div>
            </div>
            <div>
              {" "}
              <div className="font-medium text-lg mb-2">Peyronie's Disease</div>
              <div style={{ textWrap: "balanced" }}>
                Acoustic waves induce the body's natural healing response and
                helps break down scar tissue, reducing curvature of the penis.
                In additional, the bloodflow-related effects help combat any
                related erectile dysfunction.
              </div>
            </div>
            <div>
              <div className="font-medium text-lg mb-2">Custom</div>
              <div style={{ textWrap: "balanced" }}>
                Acoustic wave therapy has healing properties that are broadly
                useful. We regularly develop custom treatment plans, with high
                success rate.{" "}
                <Link to="/contact-us" className="text-blue-500">
                  Contact us
                </Link>{" "}
                to learn how we can accomodate your unique needs.
              </div>
            </div>
          </div>
        </div>
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4 text-2xl font-semibold pt-4 pb-10">
          <div className="mb-6">How shockwave therapy works</div>
          <div className="grid sm:grid-cols-4 grid-cols-1 gap-8">
            <div className="col-span-2 flex flex-col gap-2">
              <div>
                <div className="text-sm font-medium">
                  1. Delivers targeted, pulsating waves
                </div>
                <div className="text-sm mt-3 font-normal">
                  <Link to="/">State-of-the-art equipment</Link> fine-tunes the
                  waves based on the stage of the process and your unique needs.
                </div>
              </div>

              <div>
                <div className="text-sm font-medium mt-3">
                  2. Increases bloodflow
                </div>
                <div className="text-sm mt-3 font-normal">
                  The pulsations increase bloodlfow in the target region.
                  Additionally, it triggers the body's natural healing response,
                  and breaks down scar tissue.
                </div>
              </div>
              <div>
                <div className="text-sm font-medium mt-3">
                  3. Improves erectile function
                </div>
                <div className="text-sm mt-3 font-normal">
                  The cumulative healing effects of the treatment sessions mean
                  you can now enjoy firmer, more consistent erections.
                </div>
              </div>
            </div>
            <div>
              {" "}
              <StaticImage
                src="../images/menshealthpage/img2.png"
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] bg-blue-50 gap-4  pt-4 pb-10">
          <div className="mb-6 text-2xl font-semibold ">
            What sets our shockwave apart
          </div>
          <div className="max-w-2xl flex gap-4 flex-col">
            <div>
              The results from shockwave therapy highly depend on the equipment
              used. At our clinic, we have the highest quality equipment from
              Storz Medical.
            </div>
            <div>
              Additionally, while most clinics only use radial shockwave
              therapy, we use a combination of Radial + Focused shockwave for
              the best results.
            </div>
            <div className="font-semibold">Radial Shockwave</div>
            <div>
              The most common form of shockwave therapy available. It produces
              pressure waves that impact on the surface of the skin and travel
              downwards into the deeper tissues, offering deep tissue effects
              that cannot be achieved with manual massage or tools.
            </div>
            <div className="font-semibold">Focused Shockwave</div>
            <div>
              Focused shockwave is used to precisely target and penetrate deeper
              areas of the body. Focused Shockwave Therapy (FSWT) has a very
              high penetrative power and can deliver a precise beam of shockwave
              energy into a small area and specific part of the target tissue
              region.
            </div>
          </div>
        </div>
        {/*<div className="bg-blue-50 font-semibold pt-4 pb-10 text-2xl text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4">
          Frequently asked questions
        </div>*/}
      </div>
      <div className="text-left w-full">
        <MensHealthTestimonials />
      </div>
    </Layout>
  );
};

export default AboutUs;

//const vidRef = useRef(null)
//const [mouseInIframe, setMouseInIframe] = useState(false)

// Log video interactions
/*useEffect(() => {
    const setMouseIn = () => setMouseInIframe(true)
    const setMouseOut = () => setMouseInIframe(false)
    const logVideoClick = () => {
      if (mouseInIframe === true) {
        logEvent(firebaseContext.firebase, "video_interaction")
        typeof window !== "undefined" &&
          window.gtag('event', 'video_play', { send_to: "UA-154763728-1"})
      }
    }
    if (vidRef.current === null) {
      return
    } else {
      const localVidRef = vidRef.current
      localVidRef.addEventListener("mouseenter", setMouseIn)
      localVidRef.addEventListener("mouseout", setMouseOut)
      window.addEventListener("blur", logVideoClick)
      return () => {
        localVidRef.removeEventListener("mouseenter", setMouseIn)
        localVidRef.removeEventListener("mouseout", setMouseOut)
        window.removeEventListener("blur", logVideoClick)
      }
    }
  }, [vidRef, mouseInIframe])*/
